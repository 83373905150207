import { keyframes } from 'emotion'

export function animateFrom ({ from, to, fromProps = {}, toProps = {} }) {
  const fromPos = from.getBoundingClientRect()
  const fromWidth = fromPos.width
  const fromHeight = fromPos.height
  const fromTop = fromPos.top
  const fromLeft = fromPos.left

  const toPos = to.getBoundingClientRect()
  const toTop = toPos.top
  const toLeft = toPos.left
  const toWidth = toPos.width
  const toHeight = toPos.height

  const scaleX = ((fromWidth * 100) / toWidth / 100).toFixed(3)
  const scaleY = ((fromHeight * 100) / toHeight / 100).toFixed(3)
  const left = (fromLeft - toLeft).toFixed(3)
  const top = (fromTop - toTop).toFixed(3)

  return keyframes({
    from: {
      transformOrigin: 'left top',
      transform: `translate(${left}px, ${top}px) scale(${scaleX}, ${scaleY})`,
      ...fromProps,
    },
    to: {
      transformOrigin: 'left top',
      ...toProps,
    },
  })
}

export function animateTo ({ from, to, fromProps = {}, toProps = {} }) {
  const fromPos = from.getBoundingClientRect()
  const fromTop = fromPos.top
  const fromLeft = fromPos.left
  const fromWidth = fromPos.width
  const fromHeight = fromPos.height

  const toPos = to.getBoundingClientRect()
  const toTop = toPos.top
  const toLeft = toPos.left
  const toWidth = toPos.width
  const toHeight = toPos.height

  const scaleX = ((toWidth * 100) / fromWidth / 100).toFixed(3)
  const scaleY = ((toHeight * 100) / fromHeight / 100).toFixed(3)
  const left = (toLeft - fromLeft).toFixed(3)
  const top = (toTop - fromTop).toFixed(3)

  return keyframes({
    from: {
      transformOrigin: 'left top',
      ...fromProps,
    },
    to: {
      transformOrigin: 'left top',
      transform: `translate(${left}px, ${top}px) scale(${scaleX}, ${scaleY})`,
      ...toProps,
    },
  })
}
