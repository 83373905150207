import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Container from '../Container'

const bem = new BEMHelper('section')

export default function Section ({
  children,
  small,
  medium,
  large,
  fluid,
  type,
  full,
}) {
  return (
    <section {...bem('', { [type]: type, full })}>
      <Container
        nodeType="div"
        {...{
          small,
          medium: medium || (!small && !large && !fluid),
          large,
          fluid,
        }}
      >
        {children}
      </Container>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  fluid: PropTypes.bool,
  full: PropTypes.bool,
  type: PropTypes.string,
}
